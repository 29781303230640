import { Button, Carousel } from "antd";

import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SliderResponseGroupedResponseDto } from "../../../../api";
import { ChevronLeft } from "../../../../Components/icons/ChevronLeft";
import { ChevronRight } from "../../../../Components/icons/ChevronRight";
import { useNavigate } from "react-router-dom";
import {DEFAULT_FALLBACK} from "../../../../App";

interface Props {
  evidenceProducts: SliderResponseGroupedResponseDto[];
}

export const HomeCarousel = ({ evidenceProducts }: Props) => {
  const { t } = useTranslation();
  const carouselRef = useRef<any>();
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  const navigate = useNavigate()

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlePrev = () => {
    carouselRef?.current?.prev();
  };

  const handleNext = () => {
    carouselRef?.current?.next();
  };

  return (
    <div>
      {" "}
      <Carousel
        {...carouselSettings}
        afterChange={onChange}
        ref={carouselRef}
        dots={false}
        className="relative"
      >
        {evidenceProducts.map((res) => {
          return (
            <div
              key={res.id}
              className="h-full sm:mb-0 md:mb-0 lg:mb-[-15px] xl:mb-[-15px] relative"
            >
              <div
                className="relative h-[512px] w-full"
                style={{
                  background: `url(${res?.media?.path}), url(${DEFAULT_FALLBACK})`,
                  backgroundSize: "cover",
                  width:'100%',
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="flex justify-between">
                <div>
                  <div className="absolute top-72 z-50 left-[10%] w-1/3">
                    <div className="text-[#FFF] text-[32px] font-bold leading-8 tracking-[0.16px]">
                      {res?.titles[0]?.value}
                    </div>
                    <div className="text-[#FFF] text-sm font-medium leading-5 tracking-[0.07px]">
                      {res?.descriptions[0]?.value}
                    </div>
                    <div className="mt-4 underline text-[#FFF] text-xs font-semibold leading-[18px] tracking-[0.06px] cursor-pointer"
                    onClick={() => navigate(res.href)}>
                      {t("home.discover")} {">"}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="absolute top-96 z-50 right-[5%] flex justify-start">
                <div className="rounded-full bg-neutral-200 backdrop-blur-sm pt-1 pb-1 p-2 text-white font-semibold">
                  {t("home.category")}
                </div>
                <div className="rounded-full  bg-neutral-200  backdrop-blur-sm pt-1 pb-1 p-2 ml-4 text-white font-semibold">
                  {t("common.product")}
                </div>
              </div> */}
            </div>
          );
        })}
      </Carousel>
      <div>
        <Button
          type="text"
          onClick={handlePrev}
          className="absolute left-0 z-10 top-72"
        >
          <ChevronLeft></ChevronLeft>{" "}
        </Button>
        <Button
          type="text"
          onClick={handleNext}
          className="absolute right-0 z-10 top-72"
        >
          <ChevronRight fill="white"></ChevronRight>{" "}
        </Button>
      </div>
    </div>
  );
};
