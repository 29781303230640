import {Button, Col, Drawer, List, Row} from "antd";
import {useEffect, useState} from "react";

import {Paged} from "../../../utils/pagedResponse";
import {toIncludes} from "../../../utils/request";
import {ProductCard} from "../../../Components/ProductCard";

import {ButtonFilter} from "../../../Components/ButtonFilter";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import queryString from "query-string";
import {ProductBaseDto, ProductService, SlideService,} from "../../../api";
import {useTranslation} from "react-i18next";
import {translationValue} from "../../../utils/translationValue";
import {useBreakpoint} from "../../../utils/hooks/useBreakpoint";
import {ChevronDown} from "../../../Components/icons/ChevronDown";

interface Props {
  sourceName: string;
  categoryName: string;
  subCategoryName: string;
  categoryId: number | null;
  subCategoryId: number | null;
}

export const ProductsList = ({
                               sourceName,
                               categoryName,
                               subCategoryName,
                               categoryId,
                               subCategoryId,
                             }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [products, setProducts] = useState<Paged<ProductBaseDto>>();
  const [meta, setMeta] = useState<any>();
  const [openDrawerFiltersMobile, setOpenDrawerFiltersMobile] = useState(false);
  const {isMobile} = useBreakpoint();
  const {t} = useTranslation();

  const parsedParam = queryString.parse(location.search);
  const paramCategoryId = parsedParam?.category;
  let _ = {};
  const [pages, setPages] = useState([Number(parsedParam.page || 0), 56]);

  try {
    const data: string = parsedParam.q as string;
    _ = JSON.parse(decodeURIComponent(data))
  } catch (ex) {

  }
  const findProductsWithFilters = async () => {

    const variantsFilters: any = _ || {};

    if (!variantsFilters.variants) variantsFilters.variants = {}
    if (parsedParam?.category) {
      variantsFilters.categories = [
        {category: {id: Number(paramCategoryId)}},
        {category: {father: {id: Number(paramCategoryId)}}},
      ];
    }

    if (parsedParam?.source) {
      const item = await SlideService.getHomeSlideController();
      if (translationValue(item.grid.titles) === parsedParam.source) {
        variantsFilters.id = {$in: item?.grid?.products as number[]};
      } else if (
        translationValue(item.products.titles) === parsedParam.source
      ) {
        variantsFilters.id = {$in: item?.products?.products as number[]};
      }
    }


    variantsFilters.variants = {
      ...(variantsFilters.variants || {}),
      stock: {
        $gt: 0
      },
    }

    let page = pages[0];
    let pageSize = pages[1];
    if(page > 0 && !products) {
      page = 0;
      pageSize = pageSize * (pages[0] + 1)
      setPages([pages[0], 56])
    }

    ProductService.findAllProductController(
      page,
      pageSize,
      JSON.stringify(variantsFilters),
      //JSON.stringify({variants : {stock: 'desc'}}),
      undefined,
      undefined,
      toIncludes<ProductBaseDto>({
        variants: {
          media: true,
        },
        media: true,
        brand: true,
        categories: {
          category: {
            father: true,
          },
        },
      })
    )
      .then((res: any) => {
        setMeta(res.meta)
        if (pages[0] >= 1 && products) {
          const el = products?.data.concat(res.data);
          setProducts({
            ...products,
            data: el,
            hasNext: res.hasNext,
            hasPrevious: res.hasPrevious,
          });
        } else {
          setProducts(res);
        }
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   findProductsWithFilters();
  // }, [pages, location.search]);

  useEffect(() => {
    findProductsWithFilters();
  }, [searchParams]);

  const updateFilter = (v: any, page: number) => {
    setSearchParams({
      ...parsedParam,
      page: page.toString(),
      q: encodeURIComponent(JSON.stringify({...v}))
    })
  }

  return (
    <>
      {isMobile &&
        !location.pathname.includes("admin") &&
        location.pathname.includes("/products") &&
        !location.pathname.match(/\/products\/.*/) && (
          <div
            className="flex justify-end cursor-pointer"
            onClick={() => setOpenDrawerFiltersMobile(!openDrawerFiltersMobile)}
          >
            <div className="ml-auto font-semibold text-sm text-blue-400 flex justify-end mt-1">
              <div className="mr-2"> {t("common.filters")} </div>
              <div>
                <ChevronDown fill="#DBDBDB"></ChevronDown>
              </div>
            </div>
          </div>
        )}

      <Drawer
        placement={"bottom"}
        closable={false}
        onClose={() => setOpenDrawerFiltersMobile(!openDrawerFiltersMobile)}
        open={openDrawerFiltersMobile}
      >
        <div className="p-4">
          <ButtonFilter
            showButton={false}
            findProducts={(v) => {
              // findProductsWithFilters(v).then(d => {
              // })
            }}
            meta={meta}
            showSelectedFilters={false}
            findProductsWithFilters={(v: any) => {

            }}
          ></ButtonFilter>
        </div>
      </Drawer>
      <Row className="mb-10">
        <Col span={isMobile ? 0 : 6}>
          <ButtonFilter
            findProducts={(v) => {
              if (pages[0]) {
                setPages([pages[0], 56])
                updateFilter(v, pages[0])
              } else
                updateFilter(v, pages[0])
            }}
            prevFilters={_}
            showButton={false}
            meta={meta}
            showSelectedFilters={false}
            findProductsWithFilters={(v: any) => {
              // findProductsWithFilters(v);
            }}
          ></ButtonFilter>
        </Col>
        <Col span={isMobile ? 24 : 18}>
          <List
            className={isMobile ? "pl-0" : "pl-4"}
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3,
              xl: 3,
              xxl: 5,
            }}
            dataSource={products?.data}
            renderItem={(item: ProductBaseDto, index) => (
              <List.Item key={index}>
                <ProductCard
                  sourceName={sourceName}
                  categoryName={categoryName}
                  subCategoryName={subCategoryName}
                  categoryId={Number(categoryId)}
                  subCategoryId={Number(subCategoryId)}
                  data={item}
                  redirectTo={searchParams?.toString() || ''}
                />
              </List.Item>
            )}
          />
          <div className="m-auto text-center text-neutral-300 text-sm font-normal">
            <>
              {t("product.viewing")} {products?.data.length}{" "}
              {t("product.productsOf")} {products?.totalCount}
            </>
          </div>

          <div className="m-auto text-center mt-4">
            <Button
              className="bg-blue-400"
              disabled={
                products && products?.data?.length === products?.totalCount
              }
              type="primary"
              onClick={() => {
                setPages([pages[0] + 1, 56]);
                updateFilter(_, pages[0] + 1)
              }}
            >
              {t("product.loadMore")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
