import { Space, Tooltip } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { Key, useState } from "react";
import { ProductBaseDto } from "../api";
import { numberWithCommasAndFixedDecimals } from "../utils/numberWithCommasAndFixedDecimals";
import { useTranslation } from "react-i18next";
import {COLORS, DEFAULT_FALLBACK} from "../App";

interface Props {
  data: ProductBaseDto;
  sourceName?: string | undefined;
  categoryName?: string | undefined;
  subCategoryName?: string | undefined;
  categoryId?: number;
  subCategoryId?: number;
  redirectTo?: string
}

export const ProductCard = ({
  data,
  sourceName,
  categoryName,
  subCategoryName,
  categoryId,
  subCategoryId,
  redirectTo
}: Props) => {
 // const [favorite, setFavorite] = useState(false); //TODO: creare hook per lista dei favoriti per i prodotti
  const { t } = useTranslation();
  const variants = () => data?.variants.filter(d => d.stock) || []
  const location = useLocation();

  const hiddenData = {
    category: {
      categoryName: categoryName,
      categoryId: categoryId,
    },
    source: sourceName,
    subCategory: {
      subCategoryName: subCategoryName,
      subCategoryId: subCategoryId
    },
  };

  return (
    <div className="cursor-pointer mb-8 relative">
      {/* {!favorite ? (
        <HeartOutlined
          onClick={() => setFavorite(!favorite)}
          style={{ color: "white" }}
          className="absolute text-lg right-4 top-4"
        />
      ) : (
        <HeartFilled
          onClick={() => setFavorite(!favorite)}
          style={{ color: "#002655" }}
          className="absolute text-lg right-4 top-4"
        />
      )} */}
      <Link to={`/products/${data.id}`} state={{data: hiddenData }}>
        <div
          style={{
            backgroundImage: `url(${encodeURI(data?.media?.path ?? data?.variants[0]?.media?.path)}), url(${DEFAULT_FALLBACK})`,
            // background: "url(" + item.variants[0].media + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            aspectRatio: "1 / 1",
            display: "block",
            minHeight: "100%",
          }}
          className="mb-2 rounded-lg"
        ></div>

        <p className="text-blue-500 font-semibold text-sm mb-0 leading-5 capitalize tracking-[0.07px]">
          {data.name.toLowerCase()}
        </p>
        <p className="text-neutral-300 font-semibold leading-4 text-xs mb-2 capitalize">
          {data.brand?.name.toLowerCase()}
        </p>
        <p className="text-neutral-300 text-xs font-semibold mb-1">
          {data?.variants[0]?.price ? (
            numberWithCommasAndFixedDecimals(data?.variants[0]?.price)
          ) : (
            <Tooltip title={t("common.loginToViewPrice")}>**</Tooltip>
          )}{" "}
          € {t("product.each")}
        </p>
        <Space>
          {variants().slice(0, 4).map((data: { colors: string[] }, index: number) => {
            return (
                <div key={index}>
                  {index < 4 && (
                      <div className={"rounded-full flex rotate-45 size-5 border border-solid border-neutral-200 overflow-hidden"}>
                        {data.colors.filter((item, index, self) => self.findIndex(x => x === item) === index).map((color, index) => (
                            <div key={index} className={"h-full w-full"} style={{backgroundColor: COLORS[color.toLowerCase()] ?? color.toLowerCase()}}/>
                        ))}
                      </div>
            )
          }
          </div>
          )
            ;
          })}
          <div className="text-opacity-60 text-xs text-neutral-300 font-medium">
            {variants().length > 4 ? `+ ${variants().length - 4}` : ""}
          </div>
        </Space>
      </Link>
    </div>
  );
};
