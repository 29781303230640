import { Col, notification, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthService, CompanyService, ContactService, UserBaseDto, UserSectionPermissionBaseDto, UserService } from "../../../api";

import { EditPasswordModal } from "../../../Components/EdiPasswordModal";
import { InfoLine } from "../../../Components/InfoLine";
import { AuthContext } from "../../../contexts/authContext";
import { useBreakpoint } from "../../../utils/hooks/useBreakpoint";
import { useUserPermissions } from "../../../utils/hooks/useUserPermissions";
import { toIncludes } from "../../../utils/request";
import { Sections } from "../../../utils/types";

export const Profile = () => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false);

    const [personalInfo, setPersonalInfo] = useState({
        isEditing: false,
        name: "",
        surname: "",
        email: "",
    });

    const [companyInfo, setEditCompanyInfo] = useState({
        isEditing: false,
        companyName: "",
        vatNumber: "",
        taxCode: "",
        sdi: "",
        registeredOffice: "",
    });

    const { t } = useTranslation()

    const [api, contextHolder] = notification.useNotification();

    const {isMobile} = useBreakpoint()

    const { permissions} = useContext(AuthContext);
    const { canUser }= useUserPermissions(permissions, currentUser)

    const notificationSuccess = (message: string) => {
        api.success({
            message: message,
            style: {
                borderRadius: "8px",
                border: "1px solid var(--200, #92D2B4)",
                background: "#F4FCF8",
                color: "#3DA172",
            },
        });
    };

    const updateUserContext = () => {
        AuthService.meAuthController(
            undefined,
            toIncludes<UserBaseDto>({
                company: true,
                contacts: true,
                addresses: true,
                cart: {
                    rows: true,
                },
                roles: {
                    role: true,
                },
            })
        ).then((f: any) => {
            setCurrentUser(f.data);
        });
    };

    useEffect(() => {
        setPersonalInfo({
            isEditing: personalInfo.isEditing,
            name: currentUser?.name ?? "",
            surname: currentUser?.surname ?? "",
            email: currentUser?.email ?? "",
        });
        setEditCompanyInfo({
            isEditing: companyInfo.isEditing,
            companyName: currentUser?.company?.name ?? "",
            vatNumber: currentUser?.company?.vatNumber ?? "",
            taxCode: currentUser?.company?.taxCode ?? "",
            sdi: currentUser?.company?.sdi ?? "",
            registeredOffice: currentUser?.company?.registeredOffice ?? "",
        });
    }, [currentUser, companyInfo.isEditing, personalInfo.isEditing]);

    return (
        <div className={` ${isMobile ? "p-5" : "p-7 pl-14 pr-14"}`}>
            <div className="text-xl text-blue-400 font-bold leading-[30px] tracking-[0.1px]">
                {t("menu.account")}
            </div>
            <div className="text-sm text-neutral-300 font-bold leading-8 tracking-[0.12px]">
                #{currentUser?.code}
            </div>
            <div className="mt-10">
                <div className="flex justify-between">
                    <div className="text-lg text-blue-400 font-bold leading-7 tracking-[0.09px]">
                        {t('profile.personalInfo')}
                    </div>
                    <div className="text-xs text-neutral-300 underline font-medium leading-[18px] tracking-[0.06px] cursor-pointer"
                         onClick={() => {
                             if (personalInfo.isEditing) {
                                 setPersonalInfo({
                                     ...personalInfo,
                                     isEditing: !personalInfo.isEditing,
                                 });
                                 UserService.updateUserController(currentUser?.id || 0, {
                                     name: personalInfo?.name,
                                     surname: personalInfo?.surname
                                 });
                                 ContactService.updateContactController(
                                     currentUser?.contacts[0]?.id || 0,
                                     {
                                         email: personalInfo?.email,
                                     }
                                 );
                                 updateUserContext();
                                 notificationSuccess(t('profile.modifiedData'));
                             } else {
                                 setPersonalInfo({
                                     isEditing: !personalInfo.isEditing,
                                     name: currentUser?.name ?? "",
                                     surname: currentUser?.surname ?? "",
                                     email: currentUser?.email ?? "",
                                 });
                             }
                         }}
                    >
                        {!personalInfo.isEditing ? t('common.edit') : t('common.save')}
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <Row gutter={[16, 16]}>
                    <Col span={isMobile ? 24 : 12}>
                        <InfoLine
                            edit={personalInfo.isEditing}
                            label={t('common.name')}
                            item={currentUser?.name}
                            setEditItem={(value) =>
                                setPersonalInfo({
                                    ...personalInfo,
                                    name: value,
                                })
                            }
                        />
                    </Col>
                    <Col span={isMobile ? 24 : 12}>
                        <InfoLine
                            edit={personalInfo.isEditing}
                            label={t('common.surname')}
                            item={currentUser?.surname}
                            setEditItem={(value) =>
                                setPersonalInfo({
                                    ...personalInfo,
                                    surname: value,
                                })
                            }
                        />
                    </Col>
                </Row>

                <InfoLine
                    edit={personalInfo.isEditing}
                    label={t('common.email')}
                    item={currentUser?.email}
                    setEditItem={(value) =>
                        setPersonalInfo({
                            ...personalInfo,
                            email: value,
                        })
                    }
                />
                <InfoLine
                    edit={personalInfo.isEditing}
                    editPassword={() => {
                        setOpenEditPasswordModal(true);
                    }}
                    showEditPassword
                    label={t('common.password')}
                    inputType='password'
                    item={"------"}
                />
                <InfoLine
                    edit={personalInfo.isEditing}
                    label={t('common.phone')}
                    item={
                        currentUser?.contacts?.map((res: { phone: any }) => res.phone)[0]
                    }
                    setEditItem={(value) =>
                        setPersonalInfo({
                            ...personalInfo,
                        })
                    }
                />
            </div>
            {currentUser?.company && currentUser.company.id && (
                <div className="mt-10">
                    <div className="flex justify-between">
                        <div className="text-lg text-blue-400 font-bold leading-7 tracking-[0.09px]">
                            {t('profile.corporateInfo')}
                        </div>
                        <div className="text-xs text-neutral-300 underline font-medium leading-[18px] tracking-[0.06px] cursor-pointer"
                             onClick={() => {
                                 if (companyInfo.isEditing) {
                                     setEditCompanyInfo({
                                         ...companyInfo,
                                         isEditing: !companyInfo.isEditing,
                                     });
                                     CompanyService.updateCompanyController(
                                         currentUser?.company?.id || 0,
                                         {
                                             name: companyInfo.companyName,
                                             registeredOffice: companyInfo.registeredOffice,
                                             sdi: companyInfo.sdi,
                                             taxCode: companyInfo.taxCode,
                                             vatNumber: companyInfo.vatNumber,
                                         }
                                     );
                                     updateUserContext();
                                     notificationSuccess(t('profile.modifiedData'));
                                 } else {
                                     setEditCompanyInfo({
                                         isEditing: !companyInfo.isEditing,
                                         companyName: currentUser?.company?.name ?? "",
                                         vatNumber: currentUser?.company?.vatNumber ?? "",
                                         taxCode: currentUser?.company?.taxCode ?? "",
                                         sdi: currentUser?.company?.sdi ?? "",
                                         registeredOffice:
                                             currentUser?.company?.registeredOffice ?? "",
                                     });
                                 }
                             }}
                        >
                            {!companyInfo.isEditing ? t('common.edit') : t('common.save')}
                        </div>
                    </div>
                </div>
            )}
            <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12}>
                    <InfoLine
                        edit={companyInfo.isEditing}
                        label={t('common.company')}
                        item={currentUser?.company?.name}
                        setEditItem={(value) =>
                            setEditCompanyInfo({
                                ...companyInfo,
                                companyName: value,
                            })
                        }
                    />
                    <InfoLine
                        edit={companyInfo.isEditing}
                        label={t('common.vatNumber')}
                        item={currentUser?.company?.vatNumber?.toString()}
                        setEditItem={(value) =>
                            setEditCompanyInfo({
                                ...companyInfo,
                                vatNumber: value,
                            })
                        }
                    />
                </Col>
                <Col span={isMobile ? 24 : 12}>
                    <InfoLine
                        edit={companyInfo.isEditing}
                        label={t('common.taxCode')}
                        item={currentUser?.company?.taxCode?.toString()}
                        setEditItem={(value) =>
                            setEditCompanyInfo({
                                ...companyInfo,
                                taxCode: value,
                            })
                        }
                    />
                    <InfoLine
                        edit={companyInfo.isEditing}
                        label={t('common.sdi')}
                        item={currentUser?.company?.sdi?.toString()}
                        setEditItem={(value) =>
                            setEditCompanyInfo({
                                ...companyInfo,
                                sdi: value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={isMobile ? 24 : 12}>
                    <InfoLine
                        edit={companyInfo.isEditing}
                        label={t('common.registeredOffice')}
                        item={currentUser?.company?.registeredOffice}
                        setEditItem={(value) =>
                            setEditCompanyInfo({
                                ...companyInfo,
                                registeredOffice: value,
                            })
                        }
                    />
                </Col>
                <Col span={isMobile ? 0 : 12} style={{marginTop: companyInfo.isEditing ? 32 : 16}}>
                    <InfoLine edit={false} label="" item=""/>
                </Col>
            </Row>

            {/*<div className="mt-10">*/}
            {/*    <div className="flex justify-between">*/}
            {/*        <div className="text-lg text-blue-400 font-bold leading-7 tracking-[0.09px]">*/}
            {/*            {t('profile.contactPerson')}*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className="text-xs text-neutral-300 underline font-medium leading-[18px] tracking-[0.06px] cursor-pointer"*/}
            {/*            onClick={() =>*/}
            {/*                setContactInfo({*/}
            {/*                    isEditing: !contactInfo.isEditing,*/}
            {/*                    administrator:*/}
            {/*                        currentUser?.company?.administrator?.name +*/}
            {/*                        " " +*/}
            {/*                        currentUser?.company?.administrator?.surname,*/}
            {/*                    phone:*/}
            {/*                        currentUser?.company?.administrator?.contacts.map(*/}
            {/*                            (res: { phone: any }) => res.phone*/}
            {/*                        )[0] ?? "",*/}
            {/*                })*/}
            {/*            }*/}
            {/*        >*/}
            {/*            {!contactInfo.isEditing ? t('common.edit') : t('common.save')}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*  <Row gutter={[16, 16]}>*/}
            {/*      <Col span={isMobile ? 24 : 12}>*/}
            {/*          <InfoLine*/}
            {/*              edit={contactInfo.isEditing}*/}
            {/*              label={t('profile.admin')}*/}
            {/*              item={*/}
            {/*                  currentUser?.company?.administrator?.name +*/}
            {/*                  " " +*/}
            {/*                  currentUser?.company?.administrator?.surname*/}
            {/*              }*/}
            {/*              setEditItem={(value) =>*/}
            {/*                  setContactInfo({*/}
            {/*                      ...contactInfo,*/}
            {/*                      administrator: value,*/}
            {/*                  })*/}
            {/*              }*/}
            {/*          />*/}
            {/*      </Col>*/}
            {/*      <Col span={isMobile ? 24 : 12}>*/}
            {/*          <InfoLine*/}
            {/*              edit={contactInfo.isEditing}*/}
            {/*              label={t('common.contact')}*/}
            {/*              item={*/}
            {/*                  currentUser?.company?.administrator?.contacts.map(*/}
            {/*          (res: { phone: any }) => res.phone*/}
            {/*        )[0]*/}
            {/*      }*/}
            {/*      setEditItem={(value) =>*/}
            {/*        setContactInfo({*/}
            {/*          ...contactInfo,*/}
            {/*          phone: value,*/}
            {/*        })*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {contextHolder}
            <EditPasswordModal
                setShowMessage={() => notificationSuccess(t('profile.modifiedPassword'))}
                errorMessage={(message: string) => api.error({message: t(message)})}
                openEditPasswordModal={openEditPasswordModal}
                setOpenEditPasswordModal={setOpenEditPasswordModal}
            />
        </div>
    );
};
