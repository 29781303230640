import {Button, InputNumber, Table, Tag, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";

import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../contexts/authContext";
import {useBreakpoint} from "../utils/hooks/useBreakpoint";
import {isAdmin} from "../utils/isAdmin";
import {numberWithCommasAndFixedDecimals} from "../utils/numberWithCommasAndFixedDecimals";
import {ButtonFilter} from "./ButtonFilter";
import {HeartButton} from "./HeartButton";
import {Cart} from "./icons/Cart";
import {Trash} from "./icons/Trash";
import {TableProductMobile} from "../pages/guest/Products/components/TableProductMobile";
import {CloseOutlined} from "@ant-design/icons";
import {COLORS} from "../App";
import {ProductBaseDto} from "../api";

export interface ProductVariantsTableRow {
  key: any;
  prodotto: string;
  articolo: string;
  colore: string[];
  taglia: string;
  netto: any;
  stock: number;
  confezioni: any;
  quantita: number;
  totalePezzi: any;
  totale: React.ReactNode;
  dataRientro: any;
  likeId?: number | undefined;
  media: string | undefined;
  update?: boolean;
  qtyInputValue?: number;
  sconto: number;
  percentualeSconto: number;
  error?: boolean;
  productId: number;
  product: ProductBaseDto
}

interface Props {
  // variants?: GetVariantResponseDto[];
  variants?: ProductVariantsTableRow[];
  showTag: boolean;
  showFilter: boolean;
  actionButtonLabel?: string;
  onActionButtonClick?: (data: any) => void;
  title: string;
  showFido: boolean;
  productId?: string;
  showDeleteButton: boolean;
  showEditButton: boolean;
  showFavoriteButton: boolean;
  setOpenLoginModal?: (value: boolean) => void;
  totalValues: number;
  isFavoriteTable?: boolean;
  handleInputChange: (value: any, key: any, dataIndex: string) => void;
  handleTableChange: (pagination: any, filter: any, sorter: any) => void;
  pages: number[];
  setPages: (values: number[]) => void;
  isLoading: boolean;
  manageFilters: (value: any) => void;
  handleTableRefresh: () => void;
  cart?: boolean
}

export const ProductVariantsTable = ({
                                       title,
                                       variants,
                                       showFido,
                                       showFilter,
                                       actionButtonLabel,
                                       onActionButtonClick,
                                       showDeleteButton,
                                       showEditButton,
                                       showFavoriteButton,
                                       setOpenLoginModal,
                                       totalValues,
                                       handleInputChange,
                                       isFavoriteTable = false,
                                       handleTableChange,
                                       pages,
                                       setPages,
                                       isLoading,
                                       manageFilters,
                                       handleTableRefresh,
                                       productId,
                                       cart = false
                                     }: Props) => {
  const {currentUser} = useContext(AuthContext);
  const [productVariants, setProductVariants] = useState<
    ProductVariantsTableRow[] | undefined
  >(undefined);

  const location = useLocation();
  const {t} = useTranslation();

  const {isMobile} = useBreakpoint();

  const columns: ColumnsType<ProductVariantsTableRow> = [
    // {
    //   title: t("common.product"),
    //   dataIndex: "prodotto",
    //   key: "name",
    //   sorter: true,
    //   width: 190,
    //   render: (text, record) => {
    //     return (
    //       <div className="flex justify-start items-center">
    //         <div>
    //           <>
    //             <HeartButton
    //               onChange={handleTableRefresh}
    //               isFavoriteTable={isFavoriteTable}
    //               data={record}
    //               setOpenLoginModal={(value) =>
    //                 setOpenLoginModal && setOpenLoginModal(value)
    //               }
    //             />
    //           </>
    //         </div>
    //         <div className="ml-2 flex-grow">
    //           <div
    //             style={{
    //               opacity: record.stock === 0 ? 0.5 : 1,
    //             }}
    //             className="font-medium capitalize text-xs"
    //           >
    //
    //             {cart ? <a href={'/products/' + record.productId}>{text?.toLowerCase()}</a> : text?.toLowerCase()}
    //
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("common.article"),
    //   dataIndex: "articolo",
    //   key: "code",
    //   sorter: true,
    //   render: (text, record) => (
    //     <span
    //       style={{
    //         opacity: record.stock === 0 ? 0.5 : 1,
    //       }}
    //       className="text-blue-400 text-xs"
    //     >
    //       {record.product.code}
    //     </span>
    //   ),
    // },
    {
      title: t("common.color"),
      dataIndex: "colore",
      key: "color",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            {record.colore.map((item, index) => <div key={item + index}>{item}</div>)}
          </>
          // <div
          //   className={
          //     "rounded-full flex rotate-45 size-5 border border-solid border-neutral-200 overflow-hidden"
          //   }
          // >
          //   {record.colore.map((color, index) => (
          //     <div
          //       key={index}
          //       className={"h-full w-full"}
          //       style={{backgroundColor: COLORS[color.toLowerCase()] ?? color.toLowerCase()}}
          //     />
          //   ))}
          // </div>
        );
      },
    },
    {
      title: t("common.size"),
      dataIndex: "taglia",
      key: "size",
      sorter: true,
      render: (text, record) => {
        return (
          <div
            style={{
              opacity: record.stock === 0 ? 0.5 : 1,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t("common.€net"),
      dataIndex: "netto",
      key: "price",
      sorter: true,
      render: (text, record) => (
        <span
          style={{
            opacity: record.stock === 0 ? 0.5 : 1,
          }}
          className="text-xs "
        >
          {numberWithCommasAndFixedDecimals(text)} €
        </span>
      ),
    },
    {
      title: t("common.stock"),
      dataIndex: "stock",
      key: "stock",
      sorter: true,
      render: (text, record) => (
        <span
          style={{
            opacity: record.stock === 0 ? 0.5 : 1,
          }}
          className="text-xs "
        >
          {text}
        </span>
      ),
    },
    {
      title: t("common.packages"),
      dataIndex: "confezioni",
      key: "confezioni",
      render: (text, record: any, index: any) => {
        return (
          <>
            {!isAdmin(currentUser) || showFavoriteButton ? (
              record.stock > 0 ? (
                <div className="flex justify-between w-12">
                  <div>
                    <InputNumber
                      className=" w-12"
                      disabled={currentUser ? !record.netto || record.stock === 0 : record.stock === 0}
                      min={0}
                      value={text}
                      max={Math.round(record.stock / record.quantita)}
                      style={{
                        border: 1,
                        borderColor: record.error ? "red" : "lightgrey",
                        borderStyle: "solid",
                      }}
                      onChange={(value) => {
                        handleInputChange(value, record.key, "confezioni");
                      }}
                    />
                  </div>

                  <div>
                    <Button
                      style={{opacity: record.stock === 0 ? 0.5 : 1}}
                      onClick={() => {
                        handleInputChange(0, record.key, "confezioni");
                      }}
                      type="text"
                      className="w-[30%]"
                      icon={<Tooltip title={t("cart.removeItem")}><CloseOutlined/></Tooltip>}
                    ></Button>
                  </div>
                </div>
              ) : (
                <Tag color="#FEEDEC" className="rounded-lg">
                  <span className="text-[#F04438]">
                    {t("product.outOfStock")}
                  </span>
                </Tag>
              )
            ) : (
              <div>{text}</div>
            )}
          </>
        );
      },
    },
    {
      title: t("common.qntPack"),
      dataIndex: "quantita",
      key: "quantita",
      render: (text, record) => (
        <span
          style={{
            opacity: record.stock === 0 ? 0.5 : 1,
          }}
          className="text-xs "
        >
          {text}
        </span>
      ),
    },
    {
      title: t("common.totalPieces"),
      dataIndex: "totalePezzi",
      key: "totalePezzi",
      render: (text, record) => (
        <span
          style={{
            opacity: record.stock === 0 ? 0.5 : 1,
          }}
          className="text-xs "
        >
          {record.stock > 0 && record.confezioni && record.quantita ? record.confezioni * record.quantita : 0}
        </span>
      ),
    },
    {
      title: t("common.€tot"),
      dataIndex: "totale",
      key: "totale",
      width: '150px',
      render: (_, product) => {
        const unit = product.netto;
        const discounts = [
          currentUser?.company?.gammaCustomer?.discount1,
          currentUser?.company?.gammaCustomer?.discount2,
          currentUser?.company?.gammaCustomer?.discount3
        ].filter(x => x && x > 0)
          .map(x => x! / 100);
        let price = unit;
        discounts.forEach(
          x => price = price * (1-x)
        )
        const total = Math.round(price * 100) / 100 * product.confezioni * product.quantita

        return (
          <>
            <div className="flex justify-evenly text-xs">
              {
                product.percentualeSconto > 0 &&
                  <div className="text-[10px] font-normal leading-[18px] tracking-[0.05px] line-through">
                    {numberWithCommasAndFixedDecimals(price)} €
                  </div>
              }
              {

                [
                  currentUser?.company?.gammaCustomer?.discount1,
                  currentUser?.company?.gammaCustomer?.discount2,
                  currentUser?.company?.gammaCustomer?.discount3
                ].filter(d => d && d > 0)
                  .map((x, index) => <div
                    key={product.productId + '_Discount_' + index}
                    className="text-[#AE0F0A] text-[10px] font-semibold leading-[18px] tracking-[0.05px]">
                      -{x}%
                  </div>)
              }

            </div>
            <div className={'flex'}
            >
              <div style={{
                opacity: product.stock === 0 ? 0.5 : 1,
              }}
                   className="bg-blue-50 px-2 text-blue-400 rounded-xl text-left text-xs leading-4 tracking-[0.06px] font-semibold p-1">
                {product.netto != null ? (
                  product.confezioni && product.stock > 0 ? (
                    numberWithCommasAndFixedDecimals(total)
                  ) : (
                    0
                  )
                ) : (
                  <Tooltip title={t("common.loginToViewPrice")}>**</Tooltip>
                )}
                {" "}€
              </div>

            </div>
          </>
        );
      },
    },
    // {
    //   title: t("common.returnDate"),
    //   dataIndex: "dataRientro",
    //   key: "refuelingDate",
    //   sorter: true,
    //   render: (text, record) => (
    //     <span className="text-xs ">
    //       {text ? dayjs(text).format("DD-MM-YYYY") : ""}
    //     </span>
    //   ),
    //   // ellipsis: {
    //   //   showTitle: true,
    //   // },
    // },
    ...(isAdmin(currentUser) && showEditButton
      ? [
        {
          title: t("common.edit"),
          dataIndex: "modify",
          key: "modify",
          render: () => {
            return (
              <div
                className="w-full flex justify-center underline text-[#888] text-xs font-semibold leading-[18px] tracking-[0.06px]">
                {t("common.edit")}
              </div>
            );
          },
        },
      ]
      : []),
    ...(isAdmin(currentUser) && showDeleteButton
      ? [
        {
          title: t("common.delete"),
          dataIndex: "delete",
          key: "delete",
          render: () => {
            return (
              <Button type="text">
                <Trash/>
              </Button>
            );
          },
        },
      ]
      : []),
  ];

  useEffect(() => {
    setProductVariants(variants);
  }, [variants]);

  return (
    <div>
      <>
        <div className="flex justify-between h-16 items-center">
          <div>
            <div
              className="text-base leading-6 tracking-[0.08px] font-semibold text-neutral-black-700 flex align-middle">
              <span
                style={{
                  marginTop: isAdmin(currentUser) ? 4 : 0,
                }}
                className="align-middle"
              >
                {title}{" "}
              </span>
              {/* {showTag && !isMobile && !isAdmin(currentUser) && (
                <Tag
                  className="bg-[#E7F4EE] text-[#0D894F] rounded-xl text-sm leading-5 tracking-[0.07px] font-semibold align-middle ml-1"
                  bordered={false}
                >
                  Test
                </Tag>
              )} */}
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-4">
              {showFido && !isMobile && (
                <div className="text-xs leading-4 tracking-[0.06px] font-medium whitespace-normal align-middle">
                  {t("common.availableFido")}:{" "}
                  {numberWithCommasAndFixedDecimals(currentUser?.credit)}€
                </div>
              )}
            </div>
            <div className="mr-4">
              {showFilter && (
                <ButtonFilter
                  showButton
                  meta={{
                    sizes: variants?.reduce((dict, value) => {
                      dict[value.taglia] = value.taglia
                      return dict;
                    }, {} as Record<string, string>) || {},
                    brands: {},
                    categories: {},
                    colors: variants?.reduce((dict, value) => {
                      dict[value.colore[0]] = value.colore[0]
                      return dict;
                    }, {} as Record<string, string>) || {},
                  }}
                  show={
                  {
                    code: false,
                    description: false,
                    brand: false,
                    price: false
                  }
                  }
                  findProducts={() => {}}
                  showSelectedFilters={
                    isAdmin(currentUser) && location.pathname.includes("admin")
                  }
                  findProductsWithFilters={(v: any) => {
                    // findProductsWithFilters(v);
                    manageFilters(v);
                  }}
                  productId={productId}
                ></ButtonFilter>
              )}
            </div>
            <div>
              {actionButtonLabel && onActionButtonClick && (
                <Button
                  type="primary"
                  className={`bg-blue-400 text-white font-semibold text-xs ${
                    isMobile ? "h-[40px]" : "h-[46px]"
                  } w-full whitespace-normal`}
                  onClick={() => {
                    onActionButtonClick && onActionButtonClick(productVariants);
                  }}

                >
                  {!isMobile ? actionButtonLabel : <Cart fill="white"></Cart>}
                </Button>
              )}
            </div>
          </div>
        </div>
        {!isMobile ? (
          <Table
            size="small"
            className="mb-4 table-prod"
            columns={columns}
            dataSource={productVariants}
            // loading={isLoading}
            onChange={handleTableChange}
            pagination={{
              current: pages[0] + 1,
              pageSize: pages[1],
              total: totalValues,
            }}
          />
        ) : (
          <TableProductMobile
            isFavoriteTable={isFavoriteTable}
            isLoading={isLoading}
            setOpenLoginModal={(value) =>
              setOpenLoginModal && setOpenLoginModal(value)
            }
            handleInputChange={handleInputChange}
            currentUser={currentUser}
            showFavoriteButton={showFavoriteButton}
            tableData={productVariants}
            totalCount={totalValues}
            pages={pages}
            setPages={(value) => setPages(value)}
          ></TableProductMobile>
        )}
      </>
    </div>
  );
};
