import {Col, Flex, notification, Row, Spin} from "antd";
import {Layout} from "../../../Components/Layout/Layout";

import {useContext, useEffect, useState} from "react";

import {
  CartRowBaseDto,
  CartRowService,
  GetProductResponseDto,
  GetVariantResponseDto,
  ProductBaseDto,
  ProductService,
  UserBaseDto,
  VariantService,
} from "../../../api";
import {RapidOrders} from "./components/RapidOrders";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useLocation, useParams} from "react-router-dom";
import {useCart} from "../../../utils/hooks/useCart";
import {RelatedProducts} from "../../../Components/RelatedProducts";
import {ImageCarousel} from "./components/ImageCarousel";
import {ProductDetails} from "./components/ProductDetails";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../contexts/authContext";
import {ModalLogin} from "../../../Components/ModalLogin";
import {toIncludes} from "../../../utils/request";
import {useBreakpoint} from "../../../utils/hooks/useBreakpoint";
import {ProductVariantsTable, ProductVariantsTableRow} from "../../../Components/ProductVariantsTable";

export const Product = () => {

  const {t} = useTranslation();
  const {id} = useParams();
  const {setCart} = useCart();
  const {currentUser, totalCartRows} = useContext(AuthContext);
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();

  const [product, setProduct] = useState<ProductBaseDto | null>(null);
  const [productVariants, setProductVariants] = useState<ProductVariantsTableRow[]>([]);
  const [relatedProducts, setRelatedProducts] = useState<GetProductResponseDto[]>([]);
  const [categoryIdRelatedProducts, setCategoryIdRelatedProducts] = useState<number | undefined>(undefined);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [totalValues, setTotalValues] = useState<number>(0);
  const [pages, setPages] = useState([0, 10]);
  const [isLoading, setIsLoading] = useState(false);

  const hiddenData = location?.state?.data;

  const categoryName = hiddenData?.category?.categoryName ?? "";
  const categoryId = hiddenData?.category?.categoryId ?? "";

  const subCategoryName = hiddenData?.subCategory?.subCategoryName ?? "";
  const subCategoryId = hiddenData?.subCategory?.subCategoryId ?? "";

  const {isMobile} = useBreakpoint();

  const calValue = (data: any[], variantId: number) => data?.filter((el: any) => el?.variant?.id === variantId)[0]?.qty ?? 0;

  const getProduct = async () => {

    setIsLoading(true);

    try {

      const response = await ProductService.findOneProductController(
        id,
        undefined,
        toIncludes<GetProductResponseDto>({
          media: true,
          variants: {media: true},
          categories: {
            category: {
              father: true,
            },
          },
        })
      );

      setProduct(response.data);

    } catch (error) {

      if (process.env.NODE_ENV === "development") console.error("Errore durante il recupero dei dati:", error);

      api.error({
        message: t("common.errorFetchingData"),
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #bf3232)",
          background: "#fff0f0",
        },
      });

    } finally {
      setIsLoading(false);
    }
  }

  const getProductVariants = async (variantsFilters?: any, sorter?: any) => {

    if (!product) return;

    setIsLoading(true);

    let filters = variantsFilters ? variantsFilters.variants : {};

    filters["product"] = {id: {$eq: id}};
    filters["stock"] = {$gt: 0}

    try {

      const variantResponse = await VariantService.findAllVariantController(
        undefined,
        1000,
        JSON.stringify(filters),
        sorter?.columnKey ? JSON.stringify({[sorter.columnKey]: sorter.order === "ascend" ? "ASC" : "DESC",}) : '',
        undefined,
        toIncludes<GetVariantResponseDto>({
          media: true,
        })
      );

      if (product.categories && product.categories.length > 0) {
        const productCategory = product.categories[0].category;
        if (productCategory) getRelatedProducts(productCategory.id!);
      }

      const variants = variantResponse.data;

      let values: number[] = [];
      if (currentUser) {
        const cartResponse = await CartRowService.findAllCartRowController(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          toIncludes<CartRowBaseDto>({
            variant: {
              media: true,
              product: {
                media: true
              }
            }
          })
        );

        // const calValue = (data: any[], variantId: number) => data?.filter((el: any) => el?.variant?.id === variantId)[0]?.qty ?? 0;

        const cart = cartResponse.data;
        values = variants.map((res: any) => cart.filter((el: any) => el?.variant?.id === res.id)[0]?.qty ?? 0);
      }

      //TODO: Fixare logica di recupero varianti se non si è loggati

      const initialValues = variants.map((res: any, index: number) => {
        return {
          key: res?.id,
          prodotto: res?.name,
          articolo: res?.code,
          colore: res?.colors,
          taglia: res?.size,
          netto: res?.price,
          stock: res?.stock,
          confezioni: res.inCart,
          quantita: res?.qtyForPackage || 1,
          totalePezzi: res?.default,
          dataRientro: res?.refuelingDate,
          totale: "",
          sconto: res.discount,
          percentualeSconto: res.discountRate,
          likeId: res.likeId ? res.likeId : undefined,
          media: res.media ? res.media.path : undefined,
          error: false,
          variant: res,
          inCart: res.inCart,
          product: product
        }
      });

      setTotalValues(variants.totalCount);
      setProductVariants(initialValues);
    } catch (error) {
      alert()
      console.error("Errore durante il recupero dei dati:", error);
      api.error({
        message: t("common.errorFetchingData"),
        style: {
          borderRadius: "8px",
          border: "1px solid var(--200, #bf3232)",
          background: "#fff0f0",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const refreshQuantities = () => {

  }

  const getRelatedProducts = (categoryId: number) => {

    let filters: any = {};

    filters.variants = {
      stock: {
        $gt: 0,
      }
    }
    filters.categories = [
      {category: {id: Number(categoryId)}},
      {category: {father: {id: Number(categoryId)}}},
    ];

    ProductService.findAllProductController(
      undefined,
      undefined,
      JSON.stringify(filters),
      undefined,
      undefined,
      toIncludes<GetProductResponseDto>({
        variants: {
          media: true,
        },
      })
    ).then((res) => {
      setRelatedProducts(res.data);
      setCategoryIdRelatedProducts(categoryId);
    });
  };

  const handleInputChange = (value: any, key: any, dataIndex: string) => {
    setProductVariants((prevData: ProductVariantsTableRow[]) => {
      const newData = [...prevData];
      const index = newData.findIndex((item) => item.key === key);
      if (index !== -1) {
        newData[index] = {
          ...newData[index],
          [dataIndex]: value,
        };
      }

      return newData;
    });
  };

  const handleTableChange = (pagination: any, filter: any, sorter: any) => {
    if (sorter.order) {
      getProductVariants({variants: {}}, sorter);
    }
    if (pagination.current - 1 !== pages[0]) {
      setPages([pagination.current - 1, pagination.pageSize]);
      getProductVariants();
    }
  };

  const handleCartUpdate = async (
    data: any[] | undefined,
    user: UserBaseDto | undefined
  ) => {

    if (!user) return setOpenLoginModal(true);

    setOpenLoginModal(false);

    const cartRows = (data ? data.filter(x => x.netto && x.confezioni).map((row) => ({
      qty: row.confezioni,
      variant: row.key
    })) : []);

    const zeroPriceItems = data?.filter(x => !x.netto) || [];

    if (zeroPriceItems.length) {
      api.warning({
        message: t("common.messages.itemNotAvailable")
      });
    }


    const result = await setCart(cartRows).catch(
      x => new Error()
    );

    if(result instanceof Error)
      return api.error({message: t("common.messages.errorUpdatingCart")});

    let updatedVariants = [...productVariants];

    result.forEach((row) => {
      if (row.meta && row.meta.reason) api.warning({message: t(`cart.meta.${row.meta.reason}`, {variantName: row.variant.name})});

      const localVariantIndex = productVariants.findIndex((variant) => variant.key === row.variant.id);

      if (localVariantIndex !== -1) {
        updatedVariants[localVariantIndex] = {
          ...productVariants[localVariantIndex],
          confezioni: row.qty,
          qtyInputValue: row.qty,
          error: !!(row.meta && row.meta.reason)
        }
      }
    })

    setProductVariants(updatedVariants);

    api.success({message: t("common.messages.cartUpdated")});
  };

  useEffect(() => {
    getProduct();
  }, [id]);

  useEffect(() => {
    getProductVariants();
  }, [product, totalCartRows]);

  return (
    <Layout
      breadcrumb={[
        {title: t("home.home"), href: "/"},
        {title: t("common.products"), href: `${location?.state?.productUrl || "/products"}`},
        ...(hiddenData && hiddenData?.source
          ? [
            {
              title: hiddenData?.source,
              href: `/products?source=${hiddenData?.source}`,
            },
          ]
          : []),
        ...(hiddenData && hiddenData?.category?.categoryId
          ? [
            {
              title: categoryName,
              href: `/products?category=${categoryId}`,
            },
          ]
          : []),
        ...(hiddenData && hiddenData?.subCategory?.subCategoryId
          ? [
            {
              title: subCategoryName,
              href: `/products?category=${subCategoryId}`,
            },
          ]
          : []),
        {
          title: product ? product.variants[0]?.name.toLocaleLowerCase() : "",
          href: location.pathname,
        },
      ]}
      children={
        <div>
          <div className={!product ? "flex justify-center" : ""}>
            {product ? (
              <div className="xl:ml-20 xl:mr-20 lg:ml-18 lg:mr-18">
                <Row>
                  <Col span={isMobile ? 24 : 10} className={isMobile ? "mt-5" : ""}>
                    <ImageCarousel productId={product?.id ?? 0} productImage={product?.media?.path}
                                   variants={product.variants}/>
                  </Col>
                  <Col span={isMobile ? 24 : 14} className={isMobile ? "pl-0" : "pl-8"}>
                    {product && product.variants && (
                      <ProductDetails productVariant={{...product.variants[0], price: productVariants[0]?.netto}} product={product}/>
                    )}
                    <hr className={"w-full border-solid border-gray-100"}/>
                    {/*<RapidOrders variants={product.variants.filter(x => x.stock)} onRefreshVariant={getProductVariants}/>*/}
                  </Col>
                </Row>

                {contextHolder}

                {productVariants && (
                  <ProductVariantsTable
                    handleTableRefresh={() => getProductVariants()}
                    totalValues={totalValues}
                    showFido
                    showDeleteButton={false}
                    showEditButton={false}
                    showFavoriteButton={true}
                    title={t("common.summary")}
                    showFilter
                    pages={pages}
                    setPages={(value) => setPages(value)}
                    showTag
                    variants={productVariants}
                    productId={id}
                    manageFilters={(value) => getProductVariants(value)}
                    actionButtonLabel={t("common.addToCart")}
                    handleInputChange={handleInputChange}
                    handleTableChange={(pagination: any, filter: any, sorter: any) => handleTableChange(pagination, filter, sorter)}
                    onActionButtonClick={(data: any) => {
                      handleCartUpdate(data, currentUser)
                    }}
                    setOpenLoginModal={(value) => setOpenLoginModal(value)}
                    isLoading={isLoading}
                  ></ProductVariantsTable>
                )}

                <ModalLogin
                  operationAfterLogin={(user) => {
                  }
                    // handleAddItemsToCart(dataItemAddToBeforLogin, user)
                  }
                  openLoginModal={openLoginModal}
                  setOpenLoginModal={(value) => setOpenLoginModal(value)}
                ></ModalLogin>
                <div className="pt-6">
                  <div className="pl-10 font-semibold text-lg text-blue-500">
                    {t("common.relatedProducts")}
                  </div>
                  <RelatedProducts
                    relatedProducts={relatedProducts}
                    categoryId={categoryIdRelatedProducts}
                  ></RelatedProducts>
                </div>
              </div>
            ) : (
              <Flex align="center" gap="middle">
                <Spin size="large"/>
              </Flex>
            )}
          </div>
        </div>
      }
    ></Layout>
  );
};
