import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en.json'
import it from './locales/it.json'
import { initReactI18next } from 'react-i18next'

// the translations
const resources = {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
}

i18next
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnNull: false,
    fallbackLng: {
      'it': ['it'],      // Se la lingua è italiana, usa l'italiano
      'default': ['gb'], // Se la lingua è diversa dall'italiano, usa l'inglese (gb)
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  })
