import { Divider } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GetOrderResponseDto } from "../api";
import { numberWithCommasAndFixedDecimals } from "../utils/numberWithCommasAndFixedDecimals";
import { CartCard } from "./CartCard";

interface Props {
  hoverflow?: boolean;
  showTitle?: boolean;
  products: GetOrderResponseDto | undefined;
}

export const MyProductSummary = ({
  hoverflow = true,
  showTitle = true,
  products,
}: Props) => {
  const { t } = useTranslation();
  const totalProducts = useMemo(() => {
    if(!products) return 0;
    return products.rows.reduce((acc, item) => acc + item.price * (1 - item.variant?.discountRate) * item.qty * item.variant.qtyForPackage, 0);
  }, [products])
  return (
    <div
      style={{
        boxShadow: "0px 0px 5px 0px rgba(180, 180, 180, 0.25)",
        borderRadius: "4px",
        border: "1px solid var(--neutral-200, #DBDBDB)",
        padding: showTitle ? "20px" : "16px",
      }}
    >
      {showTitle && (
        <>
          <div className="flex justify-between">
            <div className="text-neutral-500 font-semibold text-xl leading-[16px] tracking-[0.1px]">
              {t("cart.cart")}
            </div>
            <div className="text-neutral-500 text-xs leading-[16px] tracking-[0.06px]">
              ({products?.rows.length} {products?.rows.length === 1 ? t("cart.item") : t("cart.items")})
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className=" font-bold text-sm leading-20 tracking-[0.07px]">
              {t("common.total")}
            </div>
            <div className=" font-bold text-sm leading-20 tracking-[0.07px]">
              {/* {numberWithCommasAndFixedDecimals(total)} € */}
              {numberWithCommasAndFixedDecimals(totalProducts)}
            </div>
          </div>
        </>
      )}
      <div className="overflow-hidden">
        <div
          className={
            hoverflow
              ? "overflow-x-auto h-96 mt-4"
              : showTitle
              ? "h-full mt-4"
              : "h-full"
          }
        >
          {products?.rows.map((item, index) => {
            return (
              <>
                <CartCard
                  id={item.id ?? 0}
                  showColor={false}
                  showSize={false}
                  showTrashButton={false}
                  name={item.variant.name}
                  brand={item.variant.product.brand.name}
                  colors={item.variant.colors}
                  size={item.variant.size}
                  price={(item.price - item.discount)* item.qty * item.variant.qtyForPackage * (1 - item.variant.discountRate)}
                  quantity={item.qty * item.variant.qtyForPackage}
                  changeImageSpan={4}
                  productId={item.variant.product.id!}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
